.mdc-data-table,
.mdc-data-table__header-cell {
  background-color: var(--color-canvas-default);
}

.mdc-data-table-toolbar {
  background-color: var(--color-canvas-default);
  border-bottom: 1px solid var(--color-border-default);
  height: 55px;
  left: 56px;
  opacity: 0;
  top: 0px;
  transition: opacity 500ms ease-in-out;
  width: calc(100% - 56px);
  z-index: -1;
}

.mdc-data-table {
  border-color: var(--color-border-default);
}

.mdc-data-table__pagination-total,
.mdc-data-table__cell,
.mdc-data-table__header-cell {
  color: var(--color-text-950);
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
  border-bottom-color: var(--color-border-default);

  &--checkbox {
    padding-left: 8px;
  }
}

.mdc-data-table__sticky {
  background-color: var(--color-canvas-default);
  left: var(--left-offset, 0);
  position: sticky !important;
  z-index: 1;
}

.mdc-data-table__pagy {
  background-color: var(--color-canvas-default);
  position: absolute;
}

.mdc-data-table__cell {
  &.mdc-data-table__cell--wrap {
    padding-bottom: 7px;
    padding-top: 7px;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  &.material-icons {
    display: table-cell;
    text-align: center;
  }
}

.mdc-data-table__pagination {
  border-top-color: var(--color-border-default);
}

.mdc-data-table--fullwidth {
  width: 100%;
}

.mdc-data-table--horizontal-scroll {
  overflow-x: unset;
  white-space: nowrap;
}

.mdc-data-table__wrap {
  white-space: normal !important;
}

.mdc-data-table--vertical-headers {
  .mdc-data-table__header-cell {
    vertical-align: bottom;
  }

  .mdc-data-table__header-cell__content {
    display: inline-block;
    overflow: visible;
    transform: rotate(180deg);
    white-space: nowrap;
    writing-mode: vertical-rl;
  }
}

.mdc-data-table__header-cell--with-sort {
  &:not(.mdc-data-table__header-cell--sorted) {
    &:not(:hover) {
      .mdc-data-table__sort-icon-button {
        color: #CCCCCC;
        content: initial;
        opacity: 1;
      }
    }
  }
}

.mdc-data-table__row {
  &.mdc-data-table__row--selected {
    background-color: var(--color-table-row-selected-bg);
  }

  .mdc-data-table__show-on-row-hover {
    display: none;
  }

  &:hover {
    .mdc-data-table__hide-on-row-hover {
      display: none;
    }

    .mdc-data-table__show-on-row-hover {
      display: initial;
    }
  }
}

.mdc-data-table__row {
  &:not(.mdc-data-table__row--selected) {
    &:hover {
      background-color: var(--color-bg-100);
    }
  }
}

.woots {
  .mdc-data-table__row {
    &.mdc-data-table__row--selected {
      background-color: rgba(38, 100, 239, .08);
    }
  }
}
